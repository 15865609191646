import { createStore } from "vuex";

export default createStore({
  state: {
    loginIn: 0,
    userInfo: {},
    commoInfo: {}, 
  },
  mutations: {
    login_type(state: any, data: number) {
      state.loginIn = data
    },
    userInfo_type(state: any, data: Object) {
      state.userInfo = { ...data }
    },
    commonInfo_type(state: any, data: any) {
      state.commoInfo = { ...data }
    },
  },
  modules: {},
});
