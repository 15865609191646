import { RouteRecordRaw } from "vue-router";

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: () => import('@/layout/Index.vue'),
    children: [
      // 首页
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/Index.vue'),
        meta: {
          nav: 'home'
        }
      },

      // 信息公告/标准科普
      // 信息公告
      {
        path: '/announcement/:type?',
        name: 'announcement',
        component: () => import('@/views/articles/Index.vue'),
        meta: {
          titleName: '信息公告',
          nav: 'article',
          detail: 'announcementDetail',
          api: 'ArticleTypeList',
          type: 0
        }
      },
      {
        path: '/announcementDetail/:id',
        name: 'announcementDetail',
        component: () => import('@/views/articles/Detail.vue'),
        meta: {
          nav: 'article',
        }
      },

      
      { // 检索中心
        path: '/searchCenter',
        name: 'searchCenter',
        component: () => import('@/views/standard/Index.vue'),
        meta: {
          titleName: '检索中心',
          nav: 'searchCenter',
          search: 'searchCenter',
          detail: 'standardDetail',
          keyword: 'standard_number'
        }
      },

      { // 批量导入
        path: '/batchImport',
        name: 'batchImport',
        component: () => import('@/views/standard/Index.vue'),
        meta: {
          titleName: '批量导入',
          nav: 'searchCenter',
          keyword: 'standard_number'
        }
      },

      { // 标准动态
        path: '/standardDynamics/:type?',
        name: 'standardDynamics',
        component: () => import('@/views/standard/Index.vue'),
        meta: {
          titleName: '标准动态',
          search: 'standardDynamics',
          detail: 'standardDetail',
          keyword: 'standard_number'
        }
      },

      { // 标准动态、检索中心的标准详情
        path: '/standardDetail',
        name: 'standardDetail',
        component: () => import('@/views/standard/Detail.vue'),
        meta: {}
      },

      // 标准化服务
      {
        path: '/standardizedServices',
        name: 'standardizedServices',
        component: () => import('@/views/standardizedServices/Index.vue'),
        meta: {
          titleName: '标准化服务',
          api: 'StandardizedServicesDetail',
          nav: 'standardizedServices',
        }
      },
      { // 标准时效性查新
        path: '/standardTimelinessSearch',
        name: 'standardTimelinessSearch',
        component: () => import('@/views/about/Index.vue'),
        meta: {
          nav: 'standardizedServices',
          api: 'StandardizedServicesDetail',
          type: 'keyOne',
          index: 1
        }
      },
      { // 标准项目立项查新
        path: '/standardProjectSearch',
        name: 'standardProjectSearch',
        component: () => import('@/views/about/Index.vue'),
        meta: {
          nav: 'standardizedServices',
          api: 'StandardizedServicesDetail',
          type: 'keyTwo',
          index: 2
        }
      },
      { // 地方标准制（修）订
        path: '/localStandardFormulation',
        name: 'localStandardFormulation',
        component: () => import('@/views/about/Index.vue'),
        meta: {
          nav: 'standardizedServices',
          api: 'StandardizedServicesDetail',
          type: 'keyThree',
          index: 3
        }
      },
      { // 标准化试点、示范项目
        path: '/standardizationPilotAndProjects',
        name: 'standardizationPilotAndProjects',
        component: () => import('@/views/about/Index.vue'),
        meta: {
          nav: 'standardizedServices',
          api: 'StandardizedServicesDetail',
          type: 'keyFour',
          index: 4
        }
      },
      { // 正版纸质标准申请
        path: '/applicationStandard',
        name: 'applicationStandard',
        component: () => import('@/views/about/Index.vue'),
        meta: {
          nav: 'standardizedServices',
          api: 'StandardizedServicesDetail',
          type: 'keyFive',
          index: 5
        }
      },
      { // 满意度调查
        path: '/satisfactionSurvey',
        name: 'satisfactionSurvey',
        component: () => import('@/views/standardizedServices/Survey.vue'),
        meta: {
          nav: 'standardizedServices',
        }
      },
      // 重点产业
      { 
        path: '/keyIndustries',
        name: 'keyIndustries',
        component: () => import('@/views/keyIndustries/Index.vue'),
        meta: {
          titleName: '重点产业',
          nav: 'keyIndustries',
        }
      },

      { // 重点产业体系列表
        path: '/keyIndustriesList/:pid/:id?',
        name: 'keyIndustriesList',
        component: () => import('@/views/keyIndustries/List.vue'),
        meta: {
          nav: 'keyIndustries',
          detail: 'standardDetail',
        }
      },

      // 标准科普
      {
        path: '/standardScience/:type?',
        name: 'standardScience',
        component: () => import('@/views/articles/Index.vue'),
        meta: {
          titleName: '标准科普',
          nav: 'science',
          detail: 'standardScienceDetail',
          type: 1
        }
      },
      
      {
        path: '/standardScienceDetail/:id',
        name: 'standardScienceDetail',
        component: () => import('@/views/articles/Detail.vue'),
        meta: {
          nav: 'article',
        }
      },

      // 关于我们
      {
        path: '/relation',
        name: 'relation',
        component: () => import('@/views/about/Index.vue'),
        meta: {
          titleName: '联系我们',
          api: 'AboutUs',
          nav: 'about',
          type: 'about',
          index: 2
        }
      },
      {
        path: '/companyProfile',
        name: 'companyProfile',
        component: () => import('@/views/about/Index.vue'),
        meta: {
          titleName: '单位简介',
          api: 'AboutUs',
          nav: 'about',
          type: 'about',
          index: 1
        }
      },

      // 用户中心
      {
        path: '/userCenter',
        name: 'userCenter',
        component: () => import('@/views/userCenter/Index.vue'),
        meta: {
          titleName: '用户中心',
          nav: 'userCenter',
          detail: 'standardDetail',
          keyword: 'standard_number'
        }
      },
      { // 404
        path: '/:catchAll(.*)', 
        name: '404',
        component: () => import('@/views/404.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/loginAndRegister/Index.vue'),
    meta: {
      titleName: '登录',
      type: 'login'
      
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/loginAndRegister/Index.vue'),
    meta: {
      titleName: '注册',
      type: 'register'
    }
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('@/views/loginAndRegister/Index.vue'),
    meta: {
      titleName: '重设密码',
      type: 'forgetPassword'
    }
  },
  {
    path: '/showPdf',
    name: 'showPdf',
    component: () => import('@/views/standard/ShowPdf.vue'),
  },
]