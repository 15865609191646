<template>
  <svg :class="svgClass" aria-hidden="true" >
    <use :xlink:href="iconName" :style="{color: color}" />
  </svg>
</template>
<script>
  export default {
    name: 'SvgIcon',
    props: {
      iconClass: {
        type: String,
        required: true
      },
      className: {
        type: String,
        default: ''
      },
      color: {
        type: String,
        default: ''
      }
    },
    computed: {
      iconName() {
        return `#icon-${this.iconClass}`
      },
      svgClass() {
        if (this.className) {
          return 'svg-icon ' + this.className
        } else {
          return 'svg-icon'
        }
      }
    }
  }
</script>
<style>
.svg-icon {
  fill: currentColor;
  overflow: hidden;
}
</style>
