import { createApp } from "vue";
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import '@arco-design/web-vue/dist/arco.css';
import '@arco-themes/vue-yicang/css/arco.css';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueWechatTitle from 'vue-wechat-title'
import './icons/index' 
import SvgIcon from '@/components/SvgIcon.vue'
import axios from 'axios';
import API from './types/require/api';

const req = require.context('./icons/svg', false,  /\.svg$/)
req.keys().map(req)


axios.interceptors.response.use((response: object) => {
  return response;
}, (error: {
  response:{
    status: number | string 
  }
}) => {
  if(error.response.status === 401 || error.response.status === 403) { //与后台约定登录失效的返回码
    router.replace('/login');
    store.commit('loginType', 0)
  } 
})

const app = createApp(App);
app.config.globalProperties.$API = API;
app.config.globalProperties.baseUrl = '/api';

app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(store);
app.use(router);
app.use(VueWechatTitle);
app.component('svg-icon', SvgIcon);
app.mount('#app');
 