import axios from './index'
const baseUrl = process.env.NODE_ENV === 'production'? '/api' : '/api';

const API = {
   // 附件管理
   UploadFile: (data = {}) => axios.post(baseUrl + '/attachment/upload', data, 2),
   DownloadFile: (data = {}) => axios.download(baseUrl + '/attachment/download', data),
   RichUploadFile: (data = {}) => axios.post(baseUrl + '/attachment/uploadForRichText', data, 2),
   DeleteFile: (data = {}, formData = null) => axios.post(baseUrl + '/attachment/delete', data, 3, true),

  // 用户
  GetEmailVerifyCode: (data = {}, formData = null) => axios.post(baseUrl + '/portal/sms/sendSms', data, formData),
  LoginPass: (data = {}, formData = null) => axios.post(baseUrl + '/portal/userFront/login', data, formData),
  LoginPhone: (data = {}, formData = null) => axios.post(baseUrl + '/portal/userFront/smsLogin', data, formData),
  LoginOut: (data = {}, formData = null) => axios.post(baseUrl + '/portal/userFront/logout', data, formData),
  Register: (data = {}, formData = null) => axios.post(baseUrl + '/portal/userFront/register', data, formData),
  GetUserInfo: (data = {}, formData = null) => axios.post(baseUrl + '/portal/userFront/detailUserFront', data, formData),
  EditUserInfo: (data = {}, formData = null) => axios.post(baseUrl + '/portal/userFront/updateUserFront', data, formData),
  ResetPassword: (data = {}, formData = null) => axios.post(baseUrl + '/portal/userFront/forgetPassword', data, formData),
  OutLogin: (data = {}, formData = null) => axios.post(baseUrl + '/portal/userFront/logout', data, true),

  // 首页
  BannerList: (data = {}, formData = null) => axios.post(baseUrl + '/portal/basicCarousel/selectAllCarousel', data, true),
  PhotoList: (data = {}, formData = null) => axios.post(baseUrl + '/portal/basicNew/selectAllNew', data, true),
  LinkList: (data = {}, formData = null) => axios.post(baseUrl + '/portal/basicLink/selectAllLink', data, true),
  ModalCon: (data = {}, formData = null) => axios.post(baseUrl + '/portal/basicPopup/selectBasicPopup', data, true),
  WebInfomation: (data = {}, formData = null) => axios.post(baseUrl + '/portal/basicWebsite/getBasicWebsite', data, true),
  HomeArticleType: (data = {}, formData = null) => axios.post(baseUrl + '/portal/articleClassification/fixedList', data, 3, true),

  // 文章管理
  ArticleTypeList: (data = {}, formData = null) => axios.post(baseUrl + '/portal/articleClassification/listByParent', data, 3),
  ArticleList: (data = {}, formData = null) => axios.post(baseUrl + '/portal/article/selectArticle', data),
  ArticleDetail: (data = {}) => axios.post(baseUrl + '/portal/article/selectDetailArticle', data),

  // 标准化服务
  StandardizedServicesDetail: (data = {}) => axios.post(baseUrl + '/portal/standardConfig/getStandardConfig', data, 3),

  // 标准搜索
  SimpleStandardList: (data = {}) => axios.post(baseUrl + '/portal/search/simpleQuery', data),
  StandardList: (data = {}) => axios.post(baseUrl + '/portal/search/seniorQuery', data, true),
  StandardDetail: (data = {}) => axios.post(baseUrl + '/portal/search/detailsQuery', data),
  StandardPdf: (data = {}) => axios.post(baseUrl + '/portal/search/standardFile', data),
  CollectStandard: (data = {}) => axios.post(baseUrl + '/portal/standardCollect/collect', data),
  MyCollectStandard: (data = {}) => axios.post(baseUrl + '/portal/standardCollect/getCollection', data),
  DeleteCollectStandard: (data = {}) => axios.post(baseUrl + '/portal/standardCollect/removeCollection', data),
  DownloadTemplateFile: (data = {}) => axios.post(baseUrl + '/portal/excel/downloadTemplate', data),
  ImportList: (data = {}) => axios.post(baseUrl + '/portal/search/excelEntryQuery', data, 2),

  // 文件管理
  FileList: (data = {}) => axios.post(baseUrl + '/portal/fileManagement/getFileList', data),
  SubmitFileForm: (data = {}) => axios.post(baseUrl + '/portal/fileManagement/saveFile', data),

  // 互动交流
  CommunicationList: (data = {}) => axios.post(baseUrl + '/portal/communication/frontPageCommitRecord', data),
  SubmitCommunication: (data = {}) => axios.post(baseUrl + '/portal/communication/submitQuestion', data),

  // 满意度调查
  SubmitSatisfactionSurvey: (data = {}) => axios.post(baseUrl + '/portal/userQuestion/insertUserQuestion', data),
  SatisfactionSurveyQueList: (data = {}) => axios.post(baseUrl + '/portal/question/getQuestionList', data),
 
  //重点产业标准
  keyIndustriesList: (data = {}) => axios.post(baseUrl + '/portal/standardReCategory/portalQueryStandardCategories', data),
  keyIndustriesDetail: (data = {}) => axios.post(baseUrl + '/portal/standardReCategory/portalDetailsQuery', data),
  keyIndustriesType: (data = {}, formData = null) => axios.post(baseUrl + '/portal/standard/standardCategory/selectFirstAndSecondLevelCategories', data, formData, true),
  keyIndustriesParentType: (data = {}) => axios.post(baseUrl + '/portal/standard/standardCategory/selectCategoryWithChildren', data, 3),
  keyIndustriesAllType: (data = {}) => axios.post(baseUrl + '/portal/standard/standardCategory/selectAllStandardCategory', data),

  // 关于我们
  AboutUs: (data = {}) => axios.post(baseUrl + '/portal/about_us/selectCheckContent', data),
}

export default API
