<template>
  <div id="app">
    <router-view  />
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    this.showUserInfo();
  },  
  methods: {
    showUserInfo() {
      this.$API.GetUserInfo().then(res => {
        if(res.data.code == 200) {
          this.$store.commit('userInfo_type', res.data.data)
          this.$store.commit('login_type', 1)
        }
      })
      this.$API.WebInfomation().then(res => {
        if(res.data.code == 200) {
          this.$store.commit('commonInfo_type', res.data.data)
          this.$nextTick(() => {
            document.getElementsByTagName('meta')['description'].content = res.data.data.description
            document.getElementsByTagName('meta')['keywords'].content = res.data.data.keyword
            document.title = res.data.data.websiteName
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import './assets/css/common.scss';
#app {
  font-family:  'PingFang SC', 'PingFang SC-Regular', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  width: 100%;
  min-width: 1200px;
  overflow-y: hidden;
  position: relative;
}

 body .table .el-table th.gutter {
  display: table-cell!important;
}

#browser {
  width: 790px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 50px;

  & > h5 {
    font-size:14px;
    font-weight:400;
    color: #C0C4CC;
    padding-top: 50px;
  }

  .wrong-browser {
    width: 160px;
	  height: 120px;
    margin: 50px auto 20px;
  }

  & > h2 {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 20px;
    color: #363636;
  }

  & > p {
    font-size: 16px;
    line-height: 30px;
    color: #999999;
  }

  .recommended-browser {
    margin-top: 50px;
    text-align: left;

    & > h2 {
      font-size: 24px;
      line-height: 20px;
      color: #363636;
      margin-bottom: 30px;
    }

    .browser-list {
      display: flex;
      margin-bottom: 30px;

      & > div {
        flex: 1;
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }

        & > img {
          display: block;
          width: 100px;
          height: 100px;
          margin: 0 auto 20px;
        }

        .el-button {
          width: 160px;
        }

        & > a {
          display: block;
          text-decoration: none;
        }


      }
    }

    .news-list {
      & > div {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #eee;

        & > a {
          font-size: 18px;
          color: #0078D7;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        & > p {
          font-size: 16px;
          color: #999999;
          margin-top: 10px;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
